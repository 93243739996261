.TabCard {
  &.tab-mobile {
    max-width: 225px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    img {
      max-width: 100%;
      max-height: 120px;
      object-fit: cover;
    }

    small {
      font-size: 0.875rem;
      font-family: "UnimedSlab-2020-SemiBold", Arial, sans-serif;
      line-height: 1.57;
      letter-spacing: 0.13px;
    }

    h3 {
      font-size: 1rem;
      font-family: "UnimedSlab-2020-SemiBold", Arial, sans-serif;
      line-height: 1.38;
    }

    p {
      font-size: 0.875rem;
      font-family: "UnimedSans-2020-Regular", Arial, sans-serif;
      line-height: 1.43;
      letter-spacing: 0.13px;

      &:first-of-type {
        margin-bottom: 26px;
      }
    }

    a {
      color: var(--white);
      font-size: 1rem;

      i.icone-arrow-right::before {
        content: '';
        font-size: 0.9rem;
      }
    }

    .tab-mobile-purple {
      background: var(--purple);
      color: var(--white);
      padding: 8px 8px 10px;
      height: 100%;
      max-height: 180px;
      display: flex;
      flex-direction: column;

      p {
        height: 42px;
      }

      a {
        margin-top: auto;
      }
    }

    .tab-mobile-secondary-400 {
      background: var(--secondary-400);
      color: var(--white);
      padding: 8px 8px 10px;
    }

    .tab-mobile-secondary-500 {
      background: var(--secondary-500);
      color: var(--white);
      padding: 8px 8px 10px;
    }

    .tab-mobile-secondary-600 {
      background: var(--secondary-600);
      color: var(--white);
      padding: 8px 8px 10px;
    }
  }
}
