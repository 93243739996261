.tab {
  display: flex;
  flex-direction: row-reverse;

  .tab-controller,
  .tab-response {
    width: 50%;
    overflow: hidden;
  }

  &-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__link {
    transition: height 200ms ease;
    height: 0;
    overflow: hidden;
  }

  &-action {
    height: max-content;
    border: none;
    text-align: left;
    padding: 0;
    margin: 0;

    &.active,
    &:focus {
      .tab__link {
        height: 20px;
      }
    }
  }

  &-container {
    padding: 24px 16px;
    color: var(--white);
    display: flex;
    line-height: 1.39;

    &-heading {
      font-family: 'UnimedSans-2020-Regular', Arial, sans-serif;
      font-size: 1rem;

      a {
        color: var(--white);
        font-size: 0.887rem;
        margin-top: 24px;

        i.icone-arrow-right::before {
          font-size: 0.8rem;
        }
      }

      &:first-of-type {
        margin-right: 16px;
        vertical-align: top;
      }

      &:last-of-type {
        display: flex;
        flex-direction: column;
        align-content: space-between;
      }

      &-text {
        font-size: 1rem;
        line-height: 1;
        font-family: 'UnimedSlab-2020-SemiBold', Arial, sans-serif;
        margin-bottom: 16px;
      }
    }
  }

  &-first {
    background: var(--purple);
    transition: height 200ms ease;
    overflow: hidden;
    &.active {
      background: var(--purple) !important;
    }
  }
  &-second {
    background: var(--secondary-400);
    transition: height 200ms ease;
    overflow: hidden;
    &.active {
      background: var(--secondary-400) !important;
    }
  }
  &-third {
    background: var(--secondary-500);
    transition: height 200ms ease;
    overflow: hidden;
    &.active {
      background: var(--secondary-500) !important;
    }
  }
  &-fourth {
    background: var(--secondary-600) !important;
    transition: height 200ms ease;
    overflow: hidden;
    &.active {
      background: var(--secondary-600) !important;
    }
  }
}
